.text-black {
  color: black !important;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;

  .productGrid__product {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.custom-mobile-SideMenu {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    padding: 0.5rem 1rem;
    // border-bottom: 1px solid #f1f1f1;
    line-height: 30px !important;
    div {
      color: #000;

      text-decoration: none;
      display: block;
      // font-size: 1.2rem;
      font-weight: 500;
    }
  }
}
.mobile-menu-side-icon {
  font-size: 1.2rem;
  margin-right: 10px;
}

#curtains {
  .custom-sub-sub-padding {
    padding: 0 0 0 35px;
    line-height: 40px !important;
  }
}

.navTitleForSubContent {
  font-size: 14px;
  font-weight: 600;
  // margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.product-wrap-10 {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.h-450 {
  height: 450px;
}

.h-w-90 {
  width: 90px;
  height: 90px;
}

.container {
  max-width: 1600px !important;
}

.font-20 {
  font-size: 20px;
}

.about-us-two-section {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  .first-sec {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .second-sec {
    .main-text {
      font-size: 14px;
      font-weight: 600;
      color: #383535;
    }
  }
}

.product-detail-content {
  .custom-size {
    font-size: 15px;
    font-weight: 500;
    color: #383535;
    padding-left: 2px;
  }

  .size-container {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    .active-border-color {
      border: 1px solid #613b18 !important;
    }

    .custom-fit-size-input {
      font-size: 14px;
      // font-weight: 600;
      color: #383535;
      margin: 0 5px;
      min-width: 300px !important;
      width: auto;
      background-color: transparent !important;
      border: 1px solid #cccccc;

      &::placeholder {
        color: #383535;
      }
    }

    .input-section {
      position: relative;
      padding-top: 5px;

      .setPosition {
        position: absolute;
        top: 15px;
        right: 15px;
        font-weight: bold;
        color: #383535;
      }
    }
    .textarea-section {
      position: relative;
      padding-top: 5px;
      width: 100%;

      textarea {
        font-size: 14px;
        // font-weight: 600;
        color: #383535;
        margin: 0 5px;
        min-width: 100% !important;
        height: 80px !important;
        width: auto;
        background-color: transparent !important;
        border: 1px solid #cccccc;
        &::placeholder {
          color: #383535;
          font-size: 13px !important;
        }
      }
      .setPosition {
        position: absolute;
        top: 15px;
        right: 15px;
        font-weight: bold;
        color: #383535;
      }
    }

    .custom-button-for-size {
      padding: 10px 20px;
      font-size: 14px;
      background-color: transparent;
      color: #000;
      border: 1px solid #cccccc;
      // border: 1px solid #613b18;
      color: #383535;
      border-radius: 0px !important;
      cursor: pointer;
      margin: 0 3px;
    }
    .custom-button-for-size-add-to-cart {
      padding: 10px 20px;
      font-size: 14px;
      background-color: transparent;
      color: #000;
      border: 1px solid #613b18;
      color: #383535;
      border-radius: 0px !important;
      cursor: pointer;
      margin: 0 3px;
      box-shadow: 5px 5px 0px 2px #623c18;
      -webkit-box-shadow: 5px 5px 0px 2px #613b18;
      -moz-box-shadow: 5px 5px 0px 2px #613b18;
    }
  }

  .image-eyelet-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding-top: 5px;

    img {
      width: 150px;
      height: 100%;
      object-fit: cover;
    }
  }

  .custom-size-large {
    font-size: 17px;
    font-weight: 600;
    color: #383535;
    padding-left: 2px;
    letter-spacing: 1px;
  }
}

.Inquiry-whatsapp-button {
  border: 1px solid green !important;
  background-color: transparent;
  padding: 10px 15px !important;
  color: green;

  &:hover {
    color: #fff !important;
  }
}

.whatsappIcon {
  font-size: 35px;
  color: green;

  &:hover {
    color: #fff;
  }
}

.Inquiry-whatsapp-button:hover .whatsappIcon {
  color: white; /* Change the WhatsApp icon color on hover */
}

.custom-curtain-sec {
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-curtain-container {
    width: 800px;
    height: 800px;
    object-fit: cover;
    position: relative;
    .customCurtainImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .svg1 {
      position: absolute;
      top: 0px;
      left: 12px;
      width: 330px;
      height: 748px;
      object-fit: cover;
      mix-blend-mode: multiply;
    }

    .svg2 {
      position: absolute;
      top: 0px;
      right: 16px;
      width: 325px;
      height: 748px;
      object-fit: cover;
      // background-color: #837070;
      mix-blend-mode: multiply;
    }
  }
}

.font-12{
  font-size: 12px !important;

}

.set-center {
  // display: flex !important;
  // justify-content: center !important;
  // align-items: center !important;
  // flex-direction: column !important;
  flex-wrap: wrap;
}

.mega-menu {
  &.custom-hovered-menu {
    li {
      &:hover {
        a.menu-trigger {
          padding-left: 12px;
          &::before {
            opacity: 1;
          }
        }
      }
      a.menu-trigger::before {
        position: absolute;
        z-index: 999;
        top: 10px;
        left: 0;
        width: 7px;
        height: 7px;
        content: "";
        transition: all 0.3s ease-in-out 0s;
        opacity: 0;
        border-radius: 50%;
        background: #a749ff none repeat scroll 0 0;
      }
      &:hover {
        div.sub-menu-wrapper {
          height: fit-content;
          overflow: visible;
        }
      }
      margin-bottom: 24px;
      a.menu-trigger {
        line-height: 1.2;
      }
      div.sub-menu-wrapper {
        padding-left: 12px;
        height: 0px;
        overflow: hidden;
        ul {
          padding-block: 12px;
          li {
            margin-bottom: 4px;
          }
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
