// body,
// html {
//   height: 100%;
//   display: flex;
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   justify-content: center;
//   align-items: center;
//   font-family: arial;
//   font-size: 12px;
// }

.couch-body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-family: arial;
  font-size: 12px;
}
.colours {
  // margin-top: 50px;
  // position: absolute;
  // bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  &__labels {
    display: flex;
    margin-bottom: 0.5em;
    span {
      display: block;
      flex: 2;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 0.9em;
      color: white;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
      margin-top: 50px;
    }
    span:first-child {
      flex: 1;
    }
  }
  &__inputs {
    display: flex;
  }
  .jscolor {
    display: block;
    border: 0;
    height: 40px;
    width: 100px;
    margin: 0;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      
    }
  }
}

.couch {
  max-width: 1000px;
  width: 100%;
  height: 394px;
  transform: scale(0.66);
  position: relative;
  transition: 0.25s ease-in-out;
  // margin-bottom: 50px;
  margin: 0 auto;
  &__overlay,
  &__img {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
  }
  

  &__overlay {
    z-index: 2;
    fill: #fcff4d;
    mix-blend-mode: multiply;
  }
  &__overlay.fade {
   
    transition: 0.6s ease-in-out;
  
  }
}

.gen-random {
  background: #222;
  border: 0;
  color: white;
  border-radius: 0 6px 6px 0;
  border-left: 1px solid white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 940px) {
  .couch {
    transform: scale(0.5);
  }
}

@media (max-width: 580px) {
  .couch {
    transform: scale(0.38);
  }
}

@media (max-width: 460px) {
  .couch {
    transform: scale(0.4);
    &__img {
    max-width: 800px;
    }&__overlay {
    width: 800px;
     
    }
  }
  .colours__inputs{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  .gen-random {
    background: #222;
    border: 0;
    color: white;
    /* border-radius: 0 6px 6px 0; */
    /* border-left: 1px solid white; */
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    padding: 10px;
}
}
 
.minorbrag {
  color: white;
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.25rem;
  display: inline-block;
}